import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 300.000000 300.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)">


<path d="M2064 2594 c3 -9 6 -36 6 -60 l0 -44 40 6 c54 9 188 -12 274 -43 241
-85 422 -301 466 -555 l11 -63 47 3 c74 5 72 2 55 89 -75 399 -416 683 -820
683 -74 0 -84 -2 -79 -16z m244 -40 c158 -33 278 -99 398 -220 90 -90 143
-174 186 -297 28 -80 36 -147 18 -147 -12 0 -20 18 -50 120 -84 287 -348 494
-669 526 -50 5 -71 11 -71 20 0 19 90 18 188 -2z"/>
<path d="M2090 2367 c0 -8 -6 -17 -13 -20 -8 -3 -24 -33 -37 -68 l-24 -62 -13
29 c-7 16 -19 46 -27 67 -11 26 -21 37 -35 37 -20 0 -21 -6 -21 -90 0 -73 3
-90 15 -90 11 0 15 12 15 52 0 58 7 56 30 -5 21 -56 47 -56 69 0 23 58 27 58
23 2 -4 -44 -2 -49 17 -49 18 0 21 6 21 46 l0 47 68 -6 c213 -18 391 -173 434
-379 l12 -58 59 0 59 0 -7 43 c-21 129 -98 272 -193 357 -102 91 -222 141
-375 155 -65 6 -77 5 -77 -8z m193 -53 c130 -31 253 -120 329 -239 37 -57 86
-190 75 -202 -14 -13 -34 8 -46 48 -42 140 -144 261 -271 322 -82 39 -179 62
-235 55 -28 -3 -35 -1 -33 11 6 31 67 33 181 5z"/>
<path d="M32 2333 c2 -12 11 -17 26 -15 21 3 22 0 22 -72 0 -69 2 -76 20 -76
18 0 20 7 20 74 0 69 2 75 22 78 12 2 23 9 26 16 3 9 -15 12 -68 12 -63 0 -71
-2 -68 -17z"/>
<path d="M195 2325 c-36 -35 -35 -105 1 -134 60 -47 134 -8 134 69 0 51 -34
90 -80 90 -19 0 -40 -9 -55 -25z m80 -65 c0 -49 -2 -55 -22 -58 -28 -4 -46 35
-37 76 10 39 14 43 37 40 20 -3 22 -9 22 -58z"/>
<path d="M360 2260 c0 -83 1 -90 20 -90 16 0 20 7 20 35 0 30 3 35 23 35 27 0
57 28 57 53 0 31 -37 57 -80 57 l-40 0 0 -90z m73 48 c5 -16 -10 -48 -23 -48
-6 0 -10 14 -10 30 0 30 24 43 33 18z"/>
<path d="M500 2260 l0 -93 52 5 c62 6 88 32 88 88 0 56 -26 82 -88 88 l-52 5
0 -93z m88 27 c18 -36 4 -81 -26 -85 -21 -3 -22 1 -22 58 0 53 2 61 17 58 10
-2 24 -16 31 -31z"/>
<path d="M675 2268 c-17 -45 -31 -86 -33 -90 -2 -4 5 -8 16 -8 12 0 23 9 27
20 5 15 15 20 40 20 24 0 34 -5 38 -20 5 -18 37 -30 37 -13 0 4 -12 44 -27 90
-22 68 -31 83 -48 83 -16 0 -26 -16 -50 -82z m64 -15 c1 -7 -8 -13 -18 -13
-18 0 -19 3 -9 32 10 28 12 30 19 12 4 -10 8 -25 8 -31z"/>
<path d="M822 2263 c3 -80 5 -88 23 -88 18 0 20 6 17 53 -2 28 -2 52 1 52 2 0
20 -25 38 -55 20 -34 40 -55 51 -55 16 0 18 10 18 90 0 83 -1 90 -20 90 -18 0
-20 -7 -20 -57 l0 -57 -36 57 c-24 38 -42 57 -55 57 -18 0 -20 -6 -17 -87z"/>
<path d="M1052 2333 c2 -12 11 -17 26 -15 21 3 22 0 22 -72 0 -69 2 -76 20
-76 18 0 20 7 20 74 0 69 2 75 22 78 12 2 23 9 26 16 3 9 -15 12 -68 12 -63 0
-71 -2 -68 -17z"/>
<path d="M1200 2260 l0 -90 60 0 c47 0 60 3 60 15 0 11 -11 15 -40 15 -36 0
-40 3 -40 25 0 21 5 25 30 25 20 0 30 5 30 15 0 10 -10 15 -30 15 -23 0 -30 4
-30 20 0 17 7 20 40 20 29 0 40 4 40 15 0 12 -13 15 -60 15 l-60 0 0 -90z"/>
<path d="M1340 2260 l0 -90 50 0 c38 0 50 4 50 15 0 10 -10 15 -30 15 l-30 0
0 75 c0 68 -2 75 -20 75 -19 0 -20 -7 -20 -90z"/>
<path d="M1460 2260 l0 -91 57 3 c68 4 89 28 24 28 -37 0 -41 2 -41 25 0 21 5
25 30 25 20 0 30 5 30 15 0 10 -10 15 -30 15 -24 0 -30 4 -30 21 0 18 5 21 35
17 29 -3 35 -1 35 14 0 15 -9 18 -55 18 l-55 0 0 -90z"/>
<path d="M1611 2324 c-28 -36 -29 -106 -1 -134 24 -24 100 -29 100 -6 0 9 -12
16 -32 18 -25 2 -35 9 -42 31 -15 41 3 82 34 80 41 -3 54 5 40 22 -21 25 -76
19 -99 -11z"/>
<path d="M1755 2325 c-36 -35 -35 -105 1 -134 60 -47 134 -8 134 69 0 51 -34
90 -80 90 -19 0 -40 -9 -55 -25z m80 -65 c0 -49 -2 -55 -22 -58 -28 -4 -46 35
-37 76 10 39 14 43 37 40 20 -3 22 -9 22 -58z"/>
<path d="M20 1855 l0 -305 175 0 175 0 0 150 0 150 103 0 102 0 0 -510 0 -510
-97 0 -98 0 0 -157 0 -158 370 3 c218 2 370 -1 370 -6 0 -5 -7 -29 -15 -52
-18 -50 -18 -60 -5 -60 6 0 10 7 10 15 0 21 30 19 46 -2 12 -16 13 -16 14 -2
0 9 -7 36 -15 59 -19 53 -19 50 10 50 23 0 24 -4 28 -62 l3 -63 2 63 2 62 191
0 c177 0 191 -1 185 -17 -5 -12 3 -26 26 -45 29 -24 31 -27 14 -34 -11 -4 -21
-2 -26 7 -5 7 -12 10 -16 6 -14 -14 22 -39 45 -32 30 9 28 49 -4 63 -14 6 -25
18 -25 27 0 18 21 20 39 3 11 -11 13 -10 8 5 -6 16 4 17 121 17 l128 0 -1 -51
c0 -61 22 -86 52 -57 9 10 13 21 9 25 -5 5 -11 2 -13 -4 -3 -7 -11 -13 -19
-13 -10 0 -14 13 -14 45 0 33 4 45 15 45 8 0 15 -7 15 -15 0 -8 5 -15 11 -15
6 0 8 8 4 19 -5 17 1 20 52 26 343 39 592 217 688 492 72 207 72 466 -1 649
-36 88 -109 197 -178 264 -31 30 -56 51 -56 48 0 -4 -23 17 -50 46 -60 64
-141 103 -230 113 l-63 6 6 -33 c4 -18 7 -43 7 -56 0 -21 5 -24 38 -24 51 0
131 -39 170 -84 34 -38 62 -96 62 -126 0 -15 7 -17 48 -12 26 2 49 6 51 9 3 2
-4 35 -16 73 -11 39 -19 70 -17 70 12 0 46 -89 47 -123 l2 -42 -55 -7 c-69 -9
-80 -5 -80 27 0 57 -72 151 -135 175 -16 7 -52 15 -80 19 l-50 6 -3 61 c-3 58
-5 62 -30 70 -15 4 -483 9 -1039 11 l-1013 4 0 -306z m719 275 c1030 5 1283 0
1328 -28 6 -4 15 -27 20 -51 l8 -44 64 -13 c103 -21 174 -83 195 -169 11 -45
24 -55 70 -55 64 0 87 10 101 43 l12 29 26 -33 c36 -48 93 -172 114 -249 25
-92 25 -326 0 -434 -74 -313 -283 -495 -643 -556 -108 -19 -152 -19 -856 -14
-409 3 -746 8 -750 11 -13 7 -9 198 4 211 7 7 39 12 72 12 48 0 64 4 83 22
l23 21 0 506 c0 604 14 551 -140 551 -132 0 -133 -1 -140 -169 l-5 -126 -130
0 -130 0 -3 254 c-2 189 1 257 10 268 9 11 24 14 53 10 22 -3 58 -2 80 2 22 4
44 5 49 3 4 -3 223 -4 485 -2z m1547 -71 c96 -45 205 -187 173 -225 -20 -24
-50 -10 -64 30 -28 80 -106 155 -177 170 -16 3 -39 8 -53 11 -16 4 -25 12 -25
24 0 38 53 35 146 -10z"/>
<path d="M1045 1866 l-26 -26 3 -506 3 -506 24 -19 c19 -16 40 -19 120 -19 91
0 99 2 121 25 l23 25 -6 497 c-7 597 6 547 -141 551 -91 3 -97 1 -121 -22z
m218 -243 c4 -124 7 -354 7 -510 l0 -283 -105 0 -105 0 0 510 0 510 98 0 99 0
6 -227z"/>
<path d="M1741 1866 l-23 -24 0 -502 0 -502 23 -25 c23 -24 28 -25 109 -20
228 12 361 182 384 489 7 96 -6 236 -31 315 l-16 52 31 36 c26 29 32 44 32 81
0 59 -22 99 -61 113 -47 16 -77 14 -121 -11 -35 -20 -42 -21 -71 -9 -56 22
-100 31 -168 31 -57 0 -69 -3 -88 -24z m479 -33 c31 -39 27 -98 -9 -134 -16
-16 -39 -29 -50 -29 -25 0 -25 5 -1 -64 61 -178 44 -467 -36 -603 -70 -120
-142 -163 -281 -171 l-93 -5 0 513 0 512 93 -4 c77 -2 102 -8 145 -30 37 -19
52 -23 52 -14 0 25 35 58 70 65 44 9 85 -4 110 -36z"/>
<path d="M2101 1844 c-30 -12 -61 -58 -61 -89 0 -29 55 -75 90 -75 42 0 90 47
90 88 0 36 -32 78 -64 84 -13 3 -38 -1 -55 -8z m73 -25 c44 -35 28 -109 -25
-109 -28 0 -69 44 -69 74 0 24 32 56 56 56 6 0 23 -9 38 -21z"/>
<path d="M2500 511 c0 -10 -9 -21 -21 -24 -17 -5 -20 -12 -17 -44 3 -34 6 -38
31 -39 27 -1 27 0 27 62 0 36 -4 64 -10 64 -5 0 -10 -8 -10 -19z m0 -61 c0
-30 -13 -40 -25 -20 -8 13 4 50 16 50 5 0 9 -13 9 -30z"/>
<path d="M1658 499 c-7 -20 7 -92 17 -96 9 -3 11 23 6 100 -1 11 -19 8 -23 -4z"/>
<path d="M2349 498 c-5 -60 11 -112 28 -93 5 4 2 10 -4 12 -15 5 -18 63 -3 63
5 0 7 7 4 15 -7 18 -24 20 -25 3z"/>
<path d="M1225 454 c10 -57 17 -62 30 -24 l12 34 6 -34 c9 -40 22 -31 31 23 4
23 3 37 -4 37 -5 0 -10 -12 -10 -27 l-1 -28 -13 30 c-14 30 -15 29 -29 -15 -4
-10 -6 -7 -6 7 -1 12 -6 25 -12 29 -9 5 -10 -4 -4 -32z"/>
<path d="M1330 481 c0 -6 6 -8 14 -5 25 10 26 -13 1 -24 -14 -7 -25 -15 -25
-19 1 -19 17 -32 38 -30 20 1 23 6 20 42 -2 33 -7 41 -25 43 -13 2 -23 -1 -23
-7z"/>
<path d="M1405 449 c12 -32 12 -45 4 -56 -13 -15 -4 -32 10 -17 7 7 41 100 41
111 0 2 -4 3 -9 3 -5 0 -12 -12 -16 -27 l-8 -28 -6 24 c-4 14 -12 26 -19 29
-8 2 -8 -8 3 -39z"/>
<path d="M1477 483 c-13 -13 -7 -32 13 -38 11 -3 20 -11 20 -17 0 -6 -9 -8
-20 -5 -24 6 -27 -11 -4 -20 8 -3 23 2 32 11 16 16 15 18 -8 36 -14 10 -21 22
-16 27 5 4 11 2 13 -5 3 -6 9 -10 14 -6 5 3 7 10 4 15 -7 10 -38 12 -48 2z"/>
<path d="M1704 479 c-5 -9 -2 -10 10 -6 28 11 40 -4 14 -17 -34 -17 -31 -51 5
-51 27 -1 28 1 25 40 -2 32 -7 41 -25 43 -11 2 -25 -2 -29 -9z"/>
<path d="M1775 473 c15 -41 17 -74 4 -89 -12 -14 -11 -16 5 -13 12 3 24 23 36
62 12 37 14 57 7 54 -6 -2 -14 -14 -18 -28 l-6 -24 -8 28 c-4 15 -12 27 -17
27 -6 0 -7 -8 -3 -17z"/>
<path d="M1967 483 c-11 -11 -8 -58 5 -71 18 -18 36 -15 48 9 17 32 4 69 -24
69 -12 0 -26 -3 -29 -7z m41 -36 c2 -19 -1 -27 -12 -27 -11 0 -16 10 -16 31 0
38 24 35 28 -4z"/>
<path d="M2040 445 c0 -25 5 -45 10 -45 6 0 10 18 10 41 0 53 23 48 31 -7 l6
-39 2 48 c1 46 0 47 -29 47 -29 0 -30 -2 -30 -45z"/>
<path d="M2120 445 c0 -25 5 -45 10 -45 6 0 10 18 10 41 0 53 23 48 31 -7 l6
-39 2 48 c1 46 0 47 -29 47 -29 0 -30 -2 -30 -45z"/>
<path d="M2207 484 c-13 -13 -7 -61 8 -74 9 -7 22 -9 32 -4 16 7 15 8 -4 11
-29 5 -30 33 -2 33 14 0 19 5 17 18 -3 17 -38 29 -51 16z"/>
<path d="M2277 484 c-13 -13 -7 -61 8 -74 16 -13 45 -7 45 10 0 7 -8 9 -20 5
-17 -5 -20 -2 -20 24 0 33 8 38 29 19 11 -11 13 -10 9 2 -5 16 -39 25 -51 14z"/>
<path d="M2397 484 c-13 -13 -7 -61 8 -74 8 -7 22 -9 32 -5 16 7 15 8 -5 14
-29 8 -30 25 -2 29 14 2 20 10 18 20 -3 18 -38 28 -51 16z"/>
</g>
</svg>



    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
